import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

import EventUsers from "./eventUsers/index.js";
import Agreements from "./agreements/index.js";
import Menu from "./menu/index.js";

export default new Vuex.Store({
  modules: {
    EventUsers,
    Agreements,
    Menu,
  },
});
