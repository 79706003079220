import axios from "axios";

// let url = window.location.href;
// let baseURL = "https://owca-alpha.paradiso-dev.xaa.pl";

// if (url.search("localhost") > -1) {
//   baseURL = "http://localhost:8000";
// }

// axios.defaults.baseURL = "http://localhost:3000";
axios.defaults.baseURL = "https://nodeapi.paradiso-dev.xaa.pl/";
