<template>
  <div class="hamburger" @click="setActiveMenu()">
    <div class="hamburger__line"></div>
    <div class="hamburger__line"></div>
    <div class="hamburger__line"></div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({
      setActiveMenu: "Menu/setActiveMenu",
    }),
  },
};
</script>

<style scoped lang="scss">
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__line {
    width: 50px;
    height: 3px;
    background: #fff;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
