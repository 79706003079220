import axios from "axios";

export default {
  state: {
    agreementsUsers: [],
  },

  getters: {
    getAgreementsUsers: (state) => state.agreementsUsers,
  },

  mutations: {
    setAgreementsUsers(state, data) {
      state.agreementsUsers = data;
    },
  },

  actions: {
    async fetchAgreementsUsers({ commit }) {
      const response = await axios.get(`/agreements`);
      console.log(response.data);
      commit("setAgreementsUsers", response.data);
    },
    
    async searchAgreementsUsers({ commit }, peyload) {
        const response = await axios.get(`/agreements/search?${peyload.field}=${peyload.value}`);
      console.log(response.data);
      commit("setAgreementsUsers", response.data);
    },
  },

  namespaced: true,
};
