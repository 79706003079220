<template>
  <div class="title">
    {{ $route.meta.title }}
    <Hamburger />
  </div>
</template>

<script>
import Hamburger from "@/components/elements/Hamburger.vue";

export default {
  components: {
    Hamburger,
  },
};
</script>

<style scoped lang="scss">
.title {
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 3px;
  padding: 20px 30px 20px 30px;
  @include border(bottom);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__line {
    width: 50px;
    height: 3px;
    background: #fff;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
