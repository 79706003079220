
export default {
  state: {
    activeMenu: false,
  },

  getters: {
    getActiveMenu: (state) => state.activeMenu,
  },

  mutations: {
    setActiveMenu(state) {
      state.activeMenu = !state.activeMenu;
    },
  },

  actions: {},

  namespaced: true,
};
