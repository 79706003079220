<template>
  <div class="btn" :class="color">
    <span>
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    color: String,
  },
};
</script>

<style scoped lang="scss">
.btn {
  opacity: 0.5;
  transition: 0.3s all;
  background: $disable;
  cursor: pointer;
  padding: 10px 2%;
  height: 40px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 1;
  }
  &--green {
    background: $green2;
  }
  &--red {
    background: $red;
  }
}
</style>
