<template>
  <div class="main" id="app">
    <div
      class="main__nav"
      :class="{ 'main__nav--active': getActiveMenu == true }"
    >
      <Menu />
    </div>
    <div class="main__pages">
      <Title />
      <div class="main__view">
        <router-view />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import Menu from "@/components/Menu.vue";
import Title from "@/components/Title.vue";

export default {
  name: "Home",
  components: {
    Menu,
    Title,
  },
  computed: {
    ...mapGetters({
      getActiveMenu: "Menu/getActiveMenu",
    }),
  },
};
</script>
<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  &__nav {
    width: 100%;
    background: $bg;
    position: absolute;
    z-index: 9;
    left: -100%;
    @media (min-width: 768px) {
      position: unset;
      width: 15%;
    }
    &--active {
      left: 0;
    }
  }
  &__pages {
    width: 100%;
    @media (min-width: 768px) {
      width: 85%;
    }
  }
  &__view {
    padding: 30px;
    @media (max-width: 768px) {
      height: 88vh;
      overflow-y: auto;
    }
  }
}
</style>
