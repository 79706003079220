import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Statystyki",
    meta: {
      title: "Statystyki",
    },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/uczestnicy",
    name: "Uczestnicy",
    meta: {
      title: "Uczestnicy",
    },
    component: () => import("../views/Participant.vue"),
  },
  {
    path: "/platnosci",
    name: "Płatności",
    meta: {
      title: "Płatności",
    },
    component: () => import("../views/Payment.vue"),
  },
  {
    path: "/skanuj",
    name: "SKanuj",
    meta: {
      title: "Owca QR",
    },
    component: () => import("../views/ScanQr.vue"),
  },
  {
    path: "/zgody",
    name: "Zgody",
    meta: {
      title: "Zgody",
    },
    component: () => import("../views/Agreement.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
