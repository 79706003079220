import axios from "axios";

export default {
  state: {
    eventUsers: [],
    eventUser: {},
    registrationInfo: {},
    bookUserd: {},
    countFolders: 0,
  },

  getters: {
    getEventUsers: (state) => state.eventUsers,
    getEventUser: (state) => state.eventUser,
    getRegistrationInfo: (state) => state.registrationInfo,
    getBookUserd: (state) => state.bookUserd,
    getcountFolders: (state) => state.countFolders,
  },

  mutations: {
    setEventUsers(state, data) {
      state.eventUsers = data;
    },
    setEventUser(state, data) {
      state.eventUser = data;
    },
    setRegistrationInfo(state, data) {
      state.registrationInfo = data;
    },
    setBookUserd(state, data) {
      state.bookUserd = data;
    },
    setcountFolders(state, data) {
      state.countFolders = data;
    },
    removeEventUser(state, data) {
      let index = state.eventUsers.findIndex((e) => e.id == data);
      if (index > -1) {
        state.eventUsers.splice(index, 1);
      }
    },
    setBookUser(state, data) {
      let user = state.eventUsers.data.find((e) => e.id == data.id);
      user.booked = data.booked;
    },
  },

  actions: {
    async fetchEventUsers({ commit }, page) {
      console.log(page);
      let url = "/eventusers?page=1";
      if (page != undefined) {
        url = "/eventusers?page=" + page;
      }
      const response = await axios.get(url);
      console.log(response.data);
      commit("setEventUsers", response.data);
      return response.data
    },

    async fetchEventUsersByParadisoId({ commit }, id) {
      const response = await axios.get(`/eventusers/paradiso/${id}`);
      console.log(response.data);
      commit("setEventUser", response.data);
    },

    async fetchRegistrationInfo({ commit }) {
      const response = await axios.get("https://owca-alpha.paradiso-dev.xaa.pl/api/user/registration");
      console.log(response.data);
      commit("setRegistrationInfo", response.data);
    },

    async saveEdit({ dispatch }, payload) {
      const response = await axios.put("/eventusers/edit/" + payload.id, payload);
      console.log(response.data);
      dispatch("fetchEventUsers");
      if (response.data.message) {
        return true;
      } else {
        return false;
      }
    },

    async saveBookUser({ commit }, payload) {
      payload.data.folder_id = parseInt(payload.data.folder_id.replace('folder_', ''))
      const response = await axios.put(`/eventusers/book/${payload.id}`, payload.data);
        console.log(response);
        commit("setBookUserd", response.data);
        return response.data
    },

    async deleteEventUsers({ commit }, id) {
      const response = await axios.delete(`/eventusers/delete/${id}`);
      console.log(response.data);
      commit("removeEventUser", id);
    },

    async searchEventUsers({ commit }, payload) {
      console.log(payload);
      const response = await axios.get(`/eventusers/search?search=${payload}`);
      console.log(response.data);
      commit("setEventUsers", response.data);
    },

    async pay({ commit }, payload) {
      console.log(payload);
      const response = await axios.post(`/payment/pay`, payload);
      console.log(response.data);
      commit("setEventUser", response.data);
    },

    async countFolders({ commit }, folder_id) {
      const response = await axios.get(`/eventusers/folder/${parseInt(folder_id.replace('folder_', ''))}`);
      console.log(response.data);
      commit("setcountFolders", response.data.folderCount);
    },
  },

  namespaced: true,
};
