<template>
  <div class="popup" :class="{ 'popup--active': show }">
    <div class="popup__box" >
      <div class="popup__title">
        <slot name="title"></slot>
      </div>
      <div class="popup__container">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["showPopup"],
  data() {
    return {
      show: false,
    };
  },
  watch: {
    showPopup: {
      handler(val) {
        this.show = val;
      },
    },
  },
  methods: {
    hide() {
      console.log("ccc");
      if (this.show == true) {
        this.show = false;
        this.$emit("closePopup", true);
      }
    },
  },
  // name: 'HelloWorld',
};
</script>

<style scoped lang="scss">
.popup {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.3);
  transition: all 0.5s;
  z-index: 0;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &--active {
      display: flex;
  }
  &__box {
    width: 60%;
    background: $bg;
    min-height: 5rem;
  }
  &__title {
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 3px;
    padding: 1rem;
    border-bottom: 1px solid #515a6b;
    span {
        color: $green;
    }
  }
  &__container {
    padding: 1rem;
  }
  &__black {
  }
}
</style>
