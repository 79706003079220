<template>
  <div class="menu">
    <div class="menu__logo">
      <Hamburger />
      <img class="" src="@/assets/icons/owca.svg" alt="" />
    </div>
    <div class="menu__nav">
      <router-link
        to="/"
        class="menu__item"
        :class="{ 'menu--active': subIsActive('/') }"
        >Home</router-link
      >
      <router-link
        to="/uczestnicy"
        class="menu__item"
        :class="{ 'menu--active': subIsActive('/uczestnicy') }"
        >Uczestnicy</router-link
      >
      <router-link
        to="/platnosci"
        class="menu__item"
        :class="{ 'menu--active': subIsActive('/platnosci') }"
        >Płatności</router-link
      >
      <router-link
        to="/skanuj"
        class="menu__item"
        :class="{ 'menu--active': subIsActive('/skanuj') }"
        >Owca QR</router-link
      >
      <router-link
        to="/zgody"
        class="menu__item"
        :class="{ 'menu--active': subIsActive('/zgody') }"
        >Zgody</router-link
      >
    </div>
  </div>
</template>

<script>
import Hamburger from "@/components/elements/Hamburger.vue";
export default {
  // name: 'HelloWorld',
  components: {
    Hamburger,
  },
  methods: {
    subIsActive(input) {
      return input == this.$route.path ? true : false;
      // console.log(input);
      // console.log(this.$route.path);
      // const paths = Array.isArray(input) ? input : [input];
      // return paths.some((path) => {
      //   return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      // });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.menu {
  border-right: 1px solid $border;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__logo {
    width: 100%;
    text-align: center;
    padding: 5%;
    background: $disable;
    position: relative;
    ::v-deep .hamburger {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    // margin-top: -30px;
    // @media (min-width: 768px) {
    //   width: 100%;
    // }

    img {
      max-width: 50%;
    }
  }
  &__nav {
    padding: 30px;
    position: relative;
  }

  &__item {
    color: $disable;
    display: block;
    margin-bottom: 15%;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 3px;
    position: relative;
    padding-bottom: 3%;
    cursor: pointer;
    transition: 0.5s all;
    &::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 0;
      background: $green;
      left: 0;
      bottom: 0;
      transition: 0.5s all;
    }
    &:hover {
      color: #fff;
      &::after {
        width: 100%;
      }
    }
  }

  &--active {
    color: #fff;
    &::after {
      width: 100%;
    }
  }
}
</style>
