import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./axios";
import store from "./store";
import VTooltip from "v-tooltip";


import "@/fonts/stylesheet.scss";
import "@/style/scss/main.scss";


Vue.use(VTooltip);

Vue.config.productionTip = false;

import Btn from "./components/elements/Btn.vue";
Vue.component("Btn", Btn);

import RightToolBar from "./components/elements/RightToolBar.vue";
Vue.component("RightToolBar", RightToolBar);

import Popup from "./components/elements/Popup.vue";
Vue.component("Popup", Popup);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
