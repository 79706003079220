<template>
  <div class="rightToolBar">
    <div
      class="rightToolBar__box"
      v-bind:class="{ 'rightToolBar--active': show }"
    >
      <div class="rightToolBar__title">
        <slot name="title"></slot>
      </div>
      <div class="rightToolBar__container">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="rightToolBar__black" v-bind:class="{ 'rightToolBar__black--active': show }" @click="hide()"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["showToolbar"],
  data() {
    return {
      show: false,
    };
  },
  watch: {
    showToolbar: {
      handler(val) {
        this.show = val;
      },
    },
  },
  methods: {
    hide() {
      console.log("ccc");
      if (this.show == true) {
        this.show = false;
        this.$emit("closeToolbar", true);
      }
    },
  },
  // name: 'HelloWorld',
};
</script>

<style scoped lang="scss">
.rightToolBar {
  &__box {
    position: fixed;
    top: 0;
    right: -30%;
    background: $bg;
    width: 30%;
    height: 100vh;
    border-left: 1px solid $border;
    transition: all 0.5s;
    z-index: 1;
  }
  &--active {
    right: 0%;
  }
  &__title {
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 3px;
    padding: 1rem;
    border-bottom: 1px solid #515a6b;
  }
  &__container {
    padding: 1rem;
  }
  &__black {
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.3);
    transition: all 0.5s;
    z-index: 0;
    &--active {
      right: 0;
    }
  }
}
</style>
